const INITIAL_STATE = {
    guestdata: null,
    error: null
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GUEST_DIRECTORY_SUCCESS':
            return { ...state, guestdata: action.payload };

        case 'GUEST_DIRECTORY_FAIL':
            return { ...state, error: action.payload };

        default:
            return state;
    }
};